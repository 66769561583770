<svg class="frame">
    <ng-container *ngIf="highlightedRelations$ | async as highlightedRelations">
        <ng-container *ngIf="highlightedSpans$ | async as highlightedSpans">
            <g *ngFor="let label of labels$ | async; let i = index; trackBy:trackById" class="relation-line-group">
                <ng-container *ngIf="isHighlighted(label.line , highlightedRelations, highlightedSpans)">
                    <g [attr.transform]="label.origin.transform">
                        <g class="app-label" [appLabel]="label" [labelFrame]="labelFrame" [labelText]="labelObj"
                            [index]="i">
                            <g class="label-group">
                                <rect class="label-frame" rx="4" #labelFrame></rect>
                                <text class="label" #labelObj>
                                    {{label.label}}
                                </text>
                            </g>
                        </g>
                    </g>
                </ng-container>
            </g>
        </ng-container>
    </ng-container>
</svg>
import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appRelation]'
})
export class RelationDirective {

  constructor(public el: ElementRef<HTMLDivElement>) { }

  @HostListener('mousemove', ['$event.offsetX', '$event.offsetY'])
  onMouseMove(x: number, y: number) {
    console.log(x, y, this.el.nativeElement.id);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OptionsBarComponent } from './options-bar.component';
import { RouterModule } from '@angular/router';
// import { ButtonModule } from 'projects/elements-lib/src/lib/button/button.module';
import { ButtonModule } from 'elements-lib';

@NgModule({
  declarations: [
    OptionsBarComponent
  ],
  imports: [
    CommonModule,
    ButtonModule,
    RouterModule
  ],
  exports: [
    OptionsBarComponent
  ]
})
export class OptionsBarModule { }

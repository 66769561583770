import { Action, createReducer, on } from '@ngrx/store';
import type { Category } from '../../../visualizer/output-type';
import { ProcessingState } from '../reports.service';
import * as ReportsActions from './reports.actions';

export const reportsFeatureKey = 'reports';

export interface State {
  pendingReportId: string;
  processingStatus: ProcessingState;
  raw: string;
  sourceString: string;
  category: Category;
  subcategory: Category;
  error?: any;

}

export const initialState: State = {
  pendingReportId: null,
  processingStatus: null,
  raw: null,
  sourceString: null,
  category: null,
  subcategory: null,
  error: null
};


export const reducer = createReducer(
  initialState,

  on(ReportsActions.loadReports, state => state),
  on(ReportsActions.loadReportsSuccess, (state, action) => state),
  on(ReportsActions.loadReportsFailure, (state, action) => state),
  on(ReportsActions.sendReport, (state, action) => {
    const { reportText, selectedCategory, selectedSubCategory } = action;
    return {
      ...initialState,
      sourceString: reportText,
      processingStatus: ProcessingState.PROCESSING,
      category: {
        value: selectedCategory
      },
      subcategory: {
        value: selectedSubCategory
      }
    }
  }),
  on(ReportsActions.sendReportSuccess, (state, action) => {
    return {
      ...state,
      pendingReportId: action.taskId,
      processingStatus: null,
      raw: null,
      error: null
    }
  }),
  on(ReportsActions.sendReportFail, (state, { error }) => {
    return {
      ...state,
      error,
    }
  }),
  on(ReportsActions.updateProcessReportStatus, (state, action) => {
    return {
      ...state,
      processingStatus: action.state,
      error: null
    }
  }),
  on(ReportsActions.reportProcessingSuccess, (state, action) => {
    return {
      ...state,
      pendingReportId: null,
      processingStatus: null,
      error: null
    }
  }),
  on(ReportsActions.fetchReportResultSuccess, (state, {data}) => {
    const sourceString = data.documents[0].text || state.sourceString;
    return {
      ...state,
      raw: JSON.stringify(data),
      sourceString,
      error: null
    }
  }),
  on(ReportsActions.cancelReport, (state) => {
    return initialState;
  })
);

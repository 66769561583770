import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Chip, ChipStatus, ToggleSwitchComponent } from 'elements-lib';
import { combineLatest, Observable } from 'rxjs';
import { map, startWith, takeWhile, tap, withLatestFrom } from 'rxjs/operators';
import { toggleAllRelationTypes, toggleRelationType } from '../../store/relation/relation.actions';
import { selectAllRelations, selectAllRelationsDisabled, selectDisabledRelations, State } from '../../store/relation/relation.reducer';

@Component({
  selector: 'app-relation-types',
  templateUrl: './relation-types.component.html',
  styleUrls: ['./relation-types.component.scss']
})
export class RelationTypesComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('toggle')
  toggleSwitch: ToggleSwitchComponent;
  allEnabled$: Observable<boolean>;
  typeChips$: Observable<Chip[]>;
  alive: boolean;

  constructor(private store: Store<State>) {
    this.alive = true;
    this.allEnabled$ = this.store.select(selectAllRelationsDisabled)
      .pipe(
        map(allDisabled => !allDisabled),
        startWith(true)
      );
  }

  ngOnInit(): void {
    this.typeChips$ = combineLatest([
      this.store.select(selectAllRelations),
      this.store.select(selectDisabledRelations)
    ])
      .pipe(

        map(([relations, disableTypes]) => {
          const chips: Chip[] = []
          const relationTypeMap = relations.reduce((map, relation) => {
            const type = relation.type;
            if (!map.has(type)) {
              map.set(type, 0);
            }
            const count = map.get(type);
            map.set(type, count + 1);
            return map;
          }, new Map<string, number>());
          relationTypeMap.forEach((count, type) => {
            const disabled = disableTypes.includes(type);
            const chip: Chip = {
              id: type,
              label: type,
              status: disabled ? ChipStatus.DISABLED : ChipStatus.ENABLED,
            }
            chips.push(chip);
          })
          return chips;
        })
      );
  }

  ngAfterViewInit(): void {
    
    this.toggleSwitch.clicked$
      .pipe(
        withLatestFrom(
          this.toggleSwitch.checked$,
        ),
        tap(([clickEvent, lastCheckedValue]) => {
          const action = toggleAllRelationTypes({
            disableAllTypes: lastCheckedValue,
          });
          this.store.dispatch(action);
        }),
        takeWhile(() => this.alive),
      ).subscribe();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  onChipClick(relation: Chip) {
    this.store.dispatch(toggleRelationType({
      relationType: relation.id
    }));
  }

}

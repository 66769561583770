import { createAction, props } from '@ngrx/store';

export const loadAppStates = createAction(
  '[AppState] Load AppStates'
);

export const fetchAppConfig = createAction(
  '[AppState] Fetch App Config'
);

export const fetchAppConfigSuccess = createAction(
  '[AppState] Fetch App Config Success',
  props<{
    emtelliproServer: string,
    iamServer: string,
    allowedExtensions: string[],
    enabledFeatures: string[]
  }>()
);

export const setServerOverride = createAction(
  '[AppState] Set server override',
  props<{ url: string }>()
);

import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, fromEvent, Observable, pipe } from 'rxjs';
import { find, map, mapTo, mergeAll, switchMap, takeWhile, tap } from 'rxjs/operators';
import { Segment } from '../section/section.component';
import { Concept } from '../store/concept/concept.model';
import * as ConceptReducers from '../store/concept/concept.reducer';
import { setHighlighted } from '../store/entity/entity.actions';
import { Entity } from '../store/entity/entity.model';
import * as EntityReducers from '../store/entity/entity.reducer';
import { selectHighlightedEntity, State } from '../store/entity/entity.reducer';
import { getSelectedOntology } from '../store/ontologies/ontologies.reducer';
import { selectVisibleRelations } from '../store/relation/relation.reducer';

@Component({
  selector: 'app-entity',
  templateUrl: './entity.component.html',
  styleUrls: ['./entity.component.scss']
})
export class EntityComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input()
  ids: string[];

  @Input('segment')
  segment: Segment;

  @ViewChild('span')
  spanElement: ElementRef;

  entity$: Observable<Entity>;
  concepts$: Observable<Concept[]>;
  selectedConcept$: Observable<Concept>;
  entityType$: Observable<string>;
  hasRelations$: Observable<boolean>;
  entityTypeDisabled$: Observable<boolean>;
  highlighted$: Observable<boolean>;
  alive: boolean;

  get id() {
    return this.ids.length > 0 ? this.ids[0] : null;
  }

  constructor(private store: Store<State>) {
    this.alive = true;
  }

  ngOnInit(): void {
    this.entity$ = this.store.select(EntityReducers.selectEntityById, this.id);
    this.hasRelations$ = this.store.select(selectVisibleRelations)
      .pipe(
        mergeAll(),
        find(relation => {
          return relation.from.includes(this.id)
            || relation.to.includes(this.id);
        }),
        mapTo(true)
      );

    const selectedOntology$ = this.store.select(getSelectedOntology);
    this.concepts$ = this.selectConcepts();
    this.selectedConcept$ = this.filterConcepts(selectedOntology$);
    this.entityType$ = this.selectEntityType(selectedOntology$);
    this.entityTypeDisabled$ = combineLatest([
      this.entityType$,
      this.store.select(EntityReducers.selectDisabledTypes),
      this.store.select(EntityReducers.selectAllTypesDisabled)
    ]).pipe(
      map(([entityType, disabledTypes, allDisabled]) => {
        return allDisabled || disabledTypes.includes(entityType);
      })
    )
  }

  ngAfterViewInit(): void {
    this.highlighted$ = this.store.pipe(
      select(selectHighlightedEntity),
      map(highlightedEntity => highlightedEntity === this.id)
    );
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  private filterConcepts(selectedOntology$: Observable<string>) {
    return combineLatest([
      selectedOntology$,
      this.concepts$.pipe(mergeAll())
    ]).pipe(
      find(([selectedOntology, concept]) => {
        return concept.ontology === selectedOntology;
      }),
      map(([selectedOntology, concept]) => concept)
    );
  }

  private selectEntityType(selectedOntology$: Observable<string>) {
    return combineLatest([
      selectedOntology$,
      this.entity$
    ]).pipe(
      map(([selectedOntology, entity]) => {
        return entity.entity_type[selectedOntology];
      })
    );
  }

  private selectConcepts() {
    return this.entity$
      .pipe(
        switchMap(x => {
          return this.store.select(ConceptReducers.selectConceptsByIds, x.concept_links);
        })
      );
  }
}

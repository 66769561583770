import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';
import { VisualizerGuard } from './shared/guards/visualizer.guard';
import { VisualizerComponent } from './visualizer/visualizer.component';

const routes: Routes = [
  {
    path: 'visualizer',
    component: VisualizerComponent,
    canActivate: [VisualizerGuard]
  },
  {
    path: 'text-entry',
    loadChildren: () => import('./pages/text-entry/text-entry.module').then(m => m.TextEntryModule),
  },
  {
    path: '',
    redirectTo: '/text-entry',
    pathMatch: 'full'
  }
];

const mainRoute: Routes = [
  {
    path: '',
    children: routes,
    canActivateChild: [AuthGuard],
  },
  {
    path: 'auth',
    loadChildren: () => import('pages-lib').then(m => m.AuthModule)
  }
]

@NgModule({
  imports: [RouterModule.forRoot(mainRoute)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Chip, ChipStatus, ToggleSwitchComponent } from 'elements-lib';
import { combineLatest, Observable } from 'rxjs';
import { startWith, takeWhile, tap, withLatestFrom, map } from 'rxjs/operators';
import { State } from '../../store';
import { toggleAll, toggleEntityType } from '../../store/entity/entity.actions';
import { selectAllTypesDisabled, selectDisabledTypes, selectEntityTypes } from '../../store/entity/entity.reducer';
import { getSelectedOntology } from '../../store/ontologies/ontologies.reducer';


@Component({
  selector: 'app-entity-types',
  templateUrl: './entity-types.component.html',
  styleUrls: ['./entity-types.component.scss']
})
export class EntityTypesComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(ToggleSwitchComponent)
  toggleSwitch: ToggleSwitchComponent;

  typeChips$: Observable<Chip[]>;
  allEnabled$: Observable<boolean>;
  alive: boolean;

  constructor(private store: Store<State>) {
    this.alive = true;
    this.allEnabled$ = this.store.select(selectAllTypesDisabled)
      .pipe(
        map(allDisabled => !allDisabled),
        startWith(true)
      );
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  ngAfterViewInit(): void {
    const selectedOntology$ = this.store.select(getSelectedOntology);
    this.toggleSwitch.clicked$
      .pipe(
        takeWhile(() => this.alive),
        withLatestFrom(
          this.toggleSwitch.checked$,
          selectedOntology$
        ),
        tap(([clickEvent, lastCheckedValue, selectedOntology]) => {
          const action = toggleAll({
            disableAllTypes: lastCheckedValue,
            selectedOntology
          });
          this.store.dispatch(action);
        })
      ).subscribe();
  }

  ngOnInit(): void {

    this.typeChips$ = combineLatest([
      this.store.select(selectEntityTypes),
      this.store.select(selectDisabledTypes),
      this.allEnabled$
    ]).pipe(
      map(([map, disabledTypes, allTypesEnabled]) => {

        const chips: Chip[] = [];
        map.forEach((occurrences, entityType) => {
          const disabled = !allTypesEnabled || disabledTypes.includes(entityType);
          const chip: Chip = {
            id: entityType,
            label: entityType,
            status: disabled ? ChipStatus.DISABLED : ChipStatus.ENABLED,
            count: occurrences
          };
          chips.push(chip);
        });
        return chips
          .sort((a, b) => {
            return a.count > b.count ? -1 : 1;
          })
          .map(chip => {
            delete chip.count;
            return chip;
          })
      })
    );
  }

  onChipClick(chip: Chip) {
    this.store.dispatch(toggleEntityType({
      entityType: chip.id
    }));
  }

}

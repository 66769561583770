import { createAction, props } from '@ngrx/store';

export const storeHydrated = createAction(
  '[Visualizer] Store Hydrated',
  props<{
    storeType: string
  }>()
);

export const clearHydratedStores = createAction(
  '[Visualizer] Clear Hydrated Stores'
);



<section #sectionElement [attr.data-label]="section.name">
    <!-- <h1>{{section.name}}</h1> -->
    <ng-template ngFor let-segment [ngForOf]="segments$ | async">
        <ng-container [ngSwitch]="segment.type">
            <ng-container *ngSwitchCase="'plain'">
                <span class="plain">{{segment.text}}</span>
            </ng-container>
            <ng-container *ngSwitchCase="'new line'">
                <span class="new-line">{{segment.text}}</span>
            </ng-container>
            <ng-container *ngSwitchCase="'entity'">
                <app-entity-group [segment]="segment"></app-entity-group>
            </ng-container>
        </ng-container>
    </ng-template>
</section>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntityTypesComponent } from './entity-types/entity-types.component';
import { SelectorsTopComponent } from './selectors-top/selectors-top.component';
import { RelationTypesComponent } from './relation-types/relation-types.component';
// import { ChipModule } from 'projects/elements-lib/src/lib/chip/chip.module';
// import { ToggleSwitchModule } from 'projects/elements-lib/src/lib/toggle-switch/toggle-switch.module';
import { ChipModule, ToggleSwitchModule } from 'elements-lib';


@NgModule({
  declarations: [
    EntityTypesComponent,
    SelectorsTopComponent,
    RelationTypesComponent
  ],
  imports: [
    CommonModule,
    ChipModule,
    ToggleSwitchModule
  ],
  exports: [
    SelectorsTopComponent
  ]
})
export class SelectorsModule { }

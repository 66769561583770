<svg class="frame">
    <defs>
        <g id="start-point">
            <rect [attr.width]="arrowSize * 2" [attr.height]="arrowSize * 2" [attr.x]="- arrowSize"
                class="start-point" />
        </g>
        <g id="arrow-down">
            <polygon [attr.points]="arrowDown" class="arrow-down" />
        </g>
        <g id="arrow-right">
            <polygon [attr.points]="arrowRight" class="arrow-right" />
        </g>
        <g id="arrow-left">
            <polygon [attr.points]="arrowLeft" class="arrow-left" />
        </g>
    </defs>
    <ng-container *ngIf="highlightedRelations$ | async as highlightedRelations">
        <ng-container *ngIf="highlightedSpans$ | async as highlightedSpans">
            <g *ngFor="let line of lines$ | async; trackBy:identify" class="relation-line-group"
                [ngClass]="{'highlighted': isHighlighted(line , highlightedRelations, highlightedSpans) }">
                <path [attr.d]="line.path" />
                <rect [attr.width]="line.startSpan.width" [attr.height]="arrowSize" [attr.x]="line.startSpan.x" [attr.y]="line.startSpan.y + line.startSpan.height - arrowSize" class="span-highlight"/>
                <rect [attr.width]="line.endSpan.width" [attr.height]="arrowSize" [attr.x]="line.endSpan.x" [attr.y]="line.endSpan.y + line.endSpan.height - arrowSize" class="span-highlight"/>
                <use xlink:href="#arrow-down" [attr.x]="line.end.x" [attr.y]="line.end.y + line.height" />
                <use xlink:href="#start-point" [attr.x]="line.start.x" [attr.y]="line.start.y + line.height" />
                <use *ngFor="let eolArrow of line.offPageConnectors"
                    [attr.xlink:href]="eolArrow.direction == '>'? '#arrow-right':'#arrow-left'"
                    [attr.x]="eolArrow.position.x" [attr.y]="eolArrow.position.y" />
            </g>
        </ng-container>
    </ng-container>
</svg>
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { catchError, concatMap, exhaustMap, filter, map, switchMap, tap } from 'rxjs/operators';
import { ProcessingState, ReportsService } from '../reports.service';
import * as ReportsActions from './reports.actions';

@Injectable()
export class ReportsEffects {

  loadReports$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReportsActions.loadReports),
      concatMap(() =>
        /** An EMPTY observable only emits completion. Replace with your own observable API request */
        EMPTY.pipe(
          map(data => ReportsActions.loadReportsSuccess({ data })),
          catchError(error => of(ReportsActions.loadReportsFailure({ error }))))
      )
    );
  });

  sendReport$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReportsActions.sendReport),
      switchMap(({ reportText, file, selectedCategory, selectedSubCategory, options }) => {
        // TODO: handle errors
        return this.reportsService
          .sendReport(reportText, file, selectedCategory, selectedSubCategory, options)
          .pipe(
            map(result => ReportsActions.sendReportSuccess({ taskId: result.id })),
            catchError(error => of(ReportsActions.sendReportFail({ error })))
          );
      })
    );
  });

  cacheSentReport = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReportsActions.sendReport),
      filter(({ reportText }) => !!reportText),
      tap(({ reportText, selectedCategory, selectedSubCategory }) => {
        const lastReport = {
          reportText,
          selectedCategory,
          selectedSubCategory
        };
        localStorage.setItem('last_report', JSON.stringify(lastReport));
      })
    );
  }, {
    dispatch: false
  });

  clear$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        '[User] Session Expired',
        '[User] Sign Out'
      ),
      tap(() => {
        localStorage.removeItem('last_report');
      })
    );
  }, {
    dispatch: false
  });

  navigateToLoadingScreen$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReportsActions.sendReport),
      tap(() => {
        this.router.navigate(['/text-entry/processing']);
      })
    )
  }, {
    dispatch: false
  });

  checkProcessingStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReportsActions.sendReportSuccess, ReportsActions.cancelReport),
      switchMap(action => {
        // TODO: handle errors
        return action.type === ReportsActions.cancelReport.type ?
          EMPTY : this.reportsService.pollProcessingStatus(action.taskId).pipe(
            filter(result => !!result),
            switchMap(({ taskId }) => {
              return of(ReportsActions.reportProcessingSuccess({ taskId }));
            })
          );
      }),

    );
  });

  getReportResults$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReportsActions.updateProcessReportStatus),
      filter(progress => progress.state === ProcessingState.SUCCESS),
      switchMap(({ taskId }) => {
        return this.reportsService.getReportResults(taskId);
      }),
      switchMap(nlpOutput => {
        return of(ReportsActions.fetchReportResultSuccess({ data: nlpOutput }));
      })
    );
  });


  constructor(
    private actions$: Actions,
    private reportsService: ReportsService,
    private router: Router,
    private route: ActivatedRoute) {

  }

}

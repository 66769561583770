import { Origin } from "../../directives/label/label.model";
import { Span } from "../../store/span/span.model";
import { Direction, OffPageConnector, Point } from "../relation.types";

export class Line {
    offPageConnectors: OffPageConnector[] = [];
    public static ROUNDNESS = 4;
    private static PADDING = 12;
    private startOffset = 0;
    private endOffset = 0;
    private labelTransforms: Map<string, Origin>;
    private labelRectTransforms: Map<string, string>;
    private labelsWidth: Map<string, string>;

    get direction(): Direction {
        const startPrecedesEnd = this.startSpan.x < this.endSpan.x || this.startSpan.y < this.endSpan.y;
        return startPrecedesEnd ? Direction.Forward : Direction.Backward;
    }

    get start(): Point {
        return {
            x: this.startSpan.x + (this.startSpan.width / 2) + this.startOffset,
            y: this.startSpan.y - this.height
        }
    }

    get end(): Point {
        return {
            x: this.endSpan.x + (this.endSpan.width / 2) + this.endOffset,
            y: this.endSpan.y - this.height
        }
    }

    get multiLine(): boolean {
        return this.startSpan.y !== this.endSpan.y;
    }

    get length(): number {
        if (!this.multiLine) {
            if (this.endSpan.x > this.startSpan.x) {
                return this.endSpan.x - this.startSpan.x;
            }
            return this.startSpan.x - this.endSpan.x;
        }
        if (this.direction === Direction.Forward) {
            return this.hostWidth - this.startSpan.x + this.endSpan.x;
        }
        return this.endSpan.x + this.hostWidth - this.startSpan.x;
    }

    get lineStart() {
        return this.direction === Direction.Forward ? this.startSpan : this.endSpan;
    }

    get lineEnd() {
        return this.direction === Direction.Forward ? this.endSpan : this.startSpan;
    }

    set lines(ls: Line[]) {
        const halfSlot = 5;
        const group = ls.filter(other => {
            const notThis = this.id !== other.id;
            const sameStartLine = other.lineStart.y === this.lineStart.y;
            const sameEndLine = other.lineEnd.y === this.lineEnd.y;
            return notThis && (sameStartLine || sameEndLine);
        });

        this.height = halfSlot;

        group.forEach(other => {
            const thisStartsFirst = this.lineStart.x < other.lineStart.x;
            const hasTheSameStart = this.lineStart.id === other.lineStart.id;
            const thisEndsAfter = (this.lineEnd.x > other.lineEnd.x && this.lineEnd.y === other.lineEnd.y) || this.multiLine;
            const hasTheSameEnd = this.lineEnd.id === other.lineEnd.id;
            const thisIsLonger = this.length > other.length;
            const thisContainsOther = (hasTheSameStart || thisStartsFirst) && (hasTheSameEnd || thisEndsAfter);

            const incrementHeight = () => {
                if (this.height <= other.height && thisContainsOther) {
                    this.height += halfSlot;
                }
            }
            if (thisIsLonger) {
                incrementHeight();
            }

            if (hasTheSameStart) {
                if (this.direction === Direction.Forward) {
                    this.startOffset += thisIsLonger ? -halfSlot : halfSlot;
                } else {
                    this.endOffset += thisIsLonger ? -halfSlot : halfSlot;
                }
            }
            if (hasTheSameEnd) {
                if (this.direction === Direction.Forward) {
                    this.endOffset += thisIsLonger ? halfSlot : -halfSlot;
                } else {
                    this.startOffset += thisIsLonger ? halfSlot : -halfSlot;
                }
            }
            if (this.lineEnd.id === other.lineStart.id) {
                if (this.direction === Direction.Forward) {
                    this.endOffset -= halfSlot;
                } else {
                    this.startOffset -= halfSlot;
                }
            }
            if (this.lineStart.id === other.lineEnd.id) {
                if (this.direction === Direction.Forward) {
                    this.startOffset += halfSlot;
                } else {
                    this.endOffset += halfSlot;
                }

            }
        });

        this.height = Math.ceil(this.height);

        if (this.multiLine) {
            const leftEdgeIsCloser = this.hostWidth - this.start.x > this.start.x && this.hostWidth - this.end.x < this.end.x;
            const inverseDirection = this.direction === Direction.Backward ? Direction.Forward : Direction.Backward;
            const direction = leftEdgeIsCloser ? inverseDirection : this.direction;
            const directionSymbol = direction > 0 ? '>' : '<';

            this.offPageConnectors.push({
                position: {
                    x: leftEdgeIsCloser ? Line.PADDING : this.hostWidth - Line.PADDING,
                    y: this.start.y
                },
                direction: directionSymbol
            });
            this.offPageConnectors.push({
                position: {
                    x: !leftEdgeIsCloser ? Line.PADDING : this.hostWidth - Line.PADDING,
                    y: this.end.y
                },
                direction: directionSymbol
            });
        }

    }

    get path(): string {
        const inverseDirection = this.direction === Direction.Backward ? Direction.Forward : Direction.Backward;
        const leftEdgeIsCloser = this.hostWidth - this.start.x > this.start.x && this.hostWidth - this.end.x < this.end.x;
        const direction = this.multiLine && leftEdgeIsCloser ? inverseDirection : this.direction;
        const moveToStart = `M${this.start.x} ${this.start.y + this.height}`;
        const lineUp = `v ${Line.ROUNDNESS - this.height}`;
        const segments = [
            moveToStart,
            lineUp,
        ];
        if (this.multiLine) {
            const roundedCorner = this.roundedCorner(true, direction);
            const lineToHostEdge = `L${leftEdgeIsCloser ? 0 : this.hostWidth} ${this.start.y}`;
            const moveToNextLine = `M${leftEdgeIsCloser ? this.hostWidth : 0} ${this.end.y}`;
            segments.push(roundedCorner);
            segments.push(lineToHostEdge);
            segments.push(moveToNextLine);
        } else {
            const roundedCorner = this.roundedCorner(true);
            segments.push(roundedCorner);
        }
        const lineToEnd = `L${this.end.x - Line.ROUNDNESS * direction} ${this.end.y}`;
        segments.push(lineToEnd);
        segments.push(this.roundedCorner(false, direction));
        segments.push(`v ${this.height}`);
        return segments.join(' ');
    }

    constructor(
        public readonly id: string,
        public startSpan: Span,
        public endSpan: Span,
        public height: number,
        public hostWidth: number,
        public labels: { [spanId: string]: string }) {
        this.labelTransforms = new Map();
        this.labelRectTransforms = new Map();
        this.labelsWidth = new Map();
    }

    getLabelTransform(id: string): Origin {
        if (this.labelTransforms.has(id)) {
            return this.labelTransforms.get(id);
        }
        const point = this.getPointById(id);
        const transform = `translate(${point.x},${point.y})`;
        if (point.x && point.y) {
            const origin: Origin = {
                ...point,
                transform
            }
            this.labelTransforms.set(id, origin);
            return origin;
        }
    }

    getLabelRectW(textElement: SVGTextElement) {
        const key = textElement.textContent;
        if (this.labelsWidth.has(key)) {
            return this.labelsWidth.get(key)
        }
        const textWidth = this.getTextWidth(textElement);
        const padding = 8;
        const width = `${Math.round(textWidth + padding)}px`;
        if (textWidth > 0) {
            this.labelsWidth.set(key, width);
        }
        return width;
    }

    getLabelRectTransform(textElement: SVGTextElement) {
        const key = textElement.textContent;
        if (this.labelRectTransforms.has(key)) {
            return this.labelRectTransforms.get(key);
        }
        const textWidth = this.getTextWidth(textElement);
        const transform = `translate(-${Math.round(textWidth / 2) + 4}, -${Line.ROUNDNESS + 18})`;
        if (textWidth > 0) {
            this.labelRectTransforms.set(key, transform);
        }
        return transform;
    }

    private getTextWidth(textElement: SVGTextElement) {
        return textElement.textLength.baseVal.value || textElement.getBBox().width;
    }

    private getPointById(id: string): Point {
        switch (id) {
            case this.startSpan.id:
                return this.start;
            case this.endSpan.id:
                return this.end;;
            default:
                return {
                    x: 0,
                    y: 0
                };
        }
    }

    private roundedCorner(upwards = false, direction = this.direction) {
        const sweep = direction > 0 ? '0 1' : '0 0';
        const r = Line.ROUNDNESS;
        const arcSize: Point = {
            x: r,
            y: r
        }
        const rotation = 0;
        return [
            `a ${arcSize.x} ${arcSize.y} ${rotation} ${sweep} ${r * direction} ${r * (upwards ? -1 : 1)}`
        ].join(' ');
    }
}

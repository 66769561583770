import { Relation } from "../store/relation/relation.model";
import { Span } from "../store/span/span.model";

export type SpanRelation = [Span[], Span[], Relation];

export enum Direction {
    Forward = 1,
    Backward = -1
}

export interface Point {
    x: number;
    y: number;
}

export interface OffPageConnector {
    position: Point;
    direction: '>' | '<';
}

export interface RelationLine {
    id: string;
    type: string;
    start: Point;
    starts: Point[];
    end: Point;
    ends: Point[];
    path: string;
    OffPageConnectors: OffPageConnector[];
    lineHeight: number;
    direction: number;
}

export interface LineBounds {
    start: Point;
    starts: Point[];
    end: Point;
    ends: Point[];
    areOnTheSameLine: boolean;
    startPrecedesEnd: boolean;
    pathStartX: number;
    pathStartY: number;
    pathEndX: number;
    pathEndY: number;
    direction: number;
}

export interface Connector {
    point: Point;
    used: boolean;
    relationId: string;
    spanId: string;
}
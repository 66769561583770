import { Action, createReducer, on } from '@ngrx/store';
import * as VisualizerActions from './visualizer.actions';

export const visualizerFeatureKey = 'visualizer-state';

export interface State {
  hydratedStores: string[]
}

export const initialState: State = {
  hydratedStores: []
};


export const reducer = createReducer(
  initialState,

  on(VisualizerActions.storeHydrated, (state, { storeType: partType }) => {
    const hydratedStores = [...state.hydratedStores];
    hydratedStores
      .push(partType);
    hydratedStores.sort();
    return {
      ...state,
      hydratedStores
    }
  }),
  on(VisualizerActions.clearHydratedStores,(state)=>{
    return {
      ...state,
      hydratedStores: []
    }
  })

);


import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, take } from 'rxjs/operators';
import type { Label } from '../directives/label/label.model';
import { Line } from '../relation/line/line';
import { Relation } from '../store/relation/relation.model';
import { selectDisabledRelations, selectVisibleRelations } from '../store/relation/relation.reducer';
import { selectSpanEntities, State } from '../store/span/span.reducer';

@Injectable({
  providedIn: 'root'
})
export class RelationsService {
  LINE_HIGHT = 8;
  lines$: Observable<Line[]>;
  labels$: Observable<any[]>;

  constructor(private store: Store<State>) {
    const relations$ = this.store.select(selectVisibleRelations);
    this.lines$ = this.makeLines(relations$);
    this.labels$ = this.collectLabels(this.lines$);
  }

  private makeLines(relations$: Observable<Relation[]>) {
    const spans$ = this.store.select(selectSpanEntities).pipe(debounceTime(100));
    const disabledRelations$ = this.store.select(selectDisabledRelations);
    return combineLatest([
      spans$,
      relations$,
      disabledRelations$
    ]).pipe(
      distinctUntilChanged(),
      map(([spans, relations, disabledTypes]) => {
        const spanIds = Object.keys(spans);
        const allRelationsLines = relations
          .filter(relation => {
            if (disabledTypes.includes(relation.type)) {
              return false;
            }
            const foundAllStarts = relation.from.every(id => spanIds.includes(id));
            const foundAllEnds = relation.to.every(id => spanIds.includes(id));
            return foundAllStarts && foundAllEnds;
          })
          .map(relation => {
            const fromEntityId = relation.from[0];
            const startSpan = spans[fromEntityId];
            return relation.to.map(toEntityId => {
              const endSpan = spans[toEntityId];
              const hostWidth = 1108;
              const line = new Line(relation.id, startSpan, endSpan, this.LINE_HIGHT, hostWidth, relation.labels);
              return line;
            });
          });
        const lines = allRelationsLines
          .reduce<Line[]>((lines, relationLines) => {
            lines.push(...relationLines);
            return lines;
          }, []).sort((a, b) => a.length - b.length);
        lines.forEach(line => line.lines = lines);
        return lines;
      })
    );
  }

  private collectLabels(labels$: Observable<Line[]>): Observable<Label[]> {
    return labels$.pipe(
      map(lines => {
        const labelsMap = new Map<string, Label>();
        lines.forEach(line => {
          const spanIds = Object.keys(line.labels);
          spanIds.forEach(spanId => {
            const label = line.labels[spanId]
            const labelId = `${line.id}${spanId}${label}`;
            const origin = line.getLabelTransform(spanId);
            if (origin) {
              const labelObj: Label = {
                id: labelId,
                spanId,
                label,
                line,
                origin
              };
              labelsMap.set(labelId, labelObj);
            }
          })
        });
        const labels = [...labelsMap.values()].sort((a, b) => {
          return a.origin.y > b.origin.y ? 1 : a.origin.y === b.origin.y ? a.origin.x > b.origin.x ? 1 : -1 : -1;
        })
        return labels;
      })
    );
  }
}

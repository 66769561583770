import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AuthInterceptorProvider } from '../Interceptors/auth-interceptor';
import { ReportsEffects } from './store/reports.effects';
import * as fromReports from './store/reports.reducer';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule,
    StoreModule.forFeature(fromReports.reportsFeatureKey, fromReports.reducer),
    EffectsModule.forFeature([ReportsEffects])
  ],
  providers: [
    AuthInterceptorProvider
  ]
})
export class ReportsModule { }

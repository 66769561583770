import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { UserSelectors } from 'pages-lib';
import { environment } from 'projects/visual-client/src/environments/environment';
import { Observable } from 'rxjs';
import { State } from '../../shared/reports/store/reports.reducer';
import { selectCategory, selectSubcategory } from '../../shared/reports/store/reports.selectors';
import type { Category } from '../output-type';

@Component({
  selector: 'app-options-bar',
  templateUrl: './options-bar.component.html',
  styleUrls: ['./options-bar.component.scss']
})
export class OptionsBarComponent{

  category$: Observable<Category>;
  subcategory$: Observable<Category>;
  homepage:string;
  loggedIn$: Observable<boolean>;

  constructor(private store: Store<State>, private router: Router) {
    this.category$ = this.store.select(selectCategory);
    this.subcategory$ = this.store.select(selectSubcategory);
    this.homepage = environment.homePath;
    this.loggedIn$ = this.store.select(UserSelectors.selectLoggedIn);
  }

}

import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/visual-client/src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private http: HttpClient;

  constructor(handler: HttpBackend) {
    this.http = new HttpClient(handler);
  }

  fetchConfig() {
    const configPath = environment.configPath;
    return this.http.get<{
      emtelliproServer: string,
      iamServer: string,
      allowedExtensions: string[],
      enabledFeatures: string[]
    }>(configPath);
  }

}

<section>
    <table>
        <tr>
            <th>REPORT CATEGORY</th>
            <th>REPORT SUBCATEGORY</th>
        </tr>
        <tr>
            <td>{{(category$ | async).value}}</td>
            <td>{{(subcategory$ | async).value}}</td>
        </tr>
    </table>
</section>
<lib-button class="button" [disabled]="!(loggedIn$ | async)" [routerLink]="[homepage]">Process New Report</lib-button>
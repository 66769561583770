<ng-container *ngIf="(entity$ | async) as entity; else simpleView">
    <div class="entity-frame" [ngClass]="{'highlighted':highlighted$ | async}">

        <span class="entity" [ngClass]="{'has-relation': (hasRelations$ | async) }"
            [attr.data-polarity]="entity.attributes.polarity" [attr.data-uncertainty]="entity.attributes.uncertainty"
            #span>{{segment.text}}</span>

        <!-- <span class="description">{{ (selectedConcept$ | async)?.ontology }}</span> -->
        <!-- <span class="description">{{ (selectedConcept$ | async)?.description }}</span> -->
        <!-- <span *ngIf="!(entityTypeDisabled$ | async)" class="description">{{ entityType$ | async }}</span> -->
        <!-- <span class="description">{{ id }}</span> -->
        <!-- <span *ngIf="!(entityTypeDisabled$ | async)" class="description">{{ entityType$ | async }}</span> -->

        <!-- <div class="meta-tab" *ngIf="!(entityTypeDisabled$ | async)">
            <div class="excerpt">
                <span class="entity-type">{{ entityType$ | async }}</span>
                <span *ngIf="entity.attributes.uncertainty === 'uncertain'" class="uncertain">U</span>
            </div>
            <div class="full">
                <span>{{entity.attributes.uncertainty}}/{{entity.attributes.polarity}}</span>
                <span class="description">{{ (selectedConcept$ | async)?.description }}</span>
            </div>
        </div> -->
    </div>

</ng-container>
<ng-template #simpleView>
    <span class="entity" #span>
        {{segment.text}}
    </span>
</ng-template>
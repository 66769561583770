import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Concept as NlpConcept, NLPOutput } from '../../output-type';
import * as VisualizerActions from '../visualizer/visualizer.actions';
import * as ConceptActions from './concept.actions';
import { Concept } from './concept.model';
import { conceptsFeatureKey } from './concept.reducer';


@Injectable()
export class ConceptEffects {

  hydrateConcepts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType('[Reports] Fetch Report Success'),
      switchMap(({ data: nlpOutput }: { data: NLPOutput }) => {
        const loadConcepts = ConceptActions.loadConcepts({
          concepts: this.getConcepts(nlpOutput)
        });
        const notifyLoadingDone = VisualizerActions.storeHydrated({
          storeType: conceptsFeatureKey
        });
        return of(loadConcepts, notifyLoadingDone);
      })
    );
  });

  clear$ = createEffect(() => {
    return this.actions$.pipe(
      ofType('[Reports] Cancel Fetch Report'),
      switchMap(() => of(ConceptActions.clearConcepts()))
    );
  });

  constructor(private actions$: Actions) { }

  private getConcepts(nlpOutput: NLPOutput) {
    const concepts: Concept[] = [];
    const ontologies = this.getOntologies(nlpOutput);
    ontologies.forEach(ontology => {
      const ontologyConcepts: NlpConcept[] = nlpOutput.documents[0].concepts[ontology];
      ontologyConcepts.forEach(nlpConcept => {
        const concept: Concept = {
          id: nlpConcept.label,
          ontology,
          ...nlpConcept
        };
        concepts.push(concept);
      });
    });
    return concepts
  }

  private getOntologies(nlpOutput: NLPOutput) {
    return Object.keys(nlpOutput.documents[0].concepts);
  }

}

import { createAction, props } from '@ngrx/store';
import { AdvancedOptionsState } from '../../../pages/text-entry/advanced-options/advanced-options.state';
import { NLPOutput } from '../../../visualizer/output-type';
import { ProcessingState } from '../reports.service';

export const loadReports = createAction(
  '[Reports] Load Reports'
);

export const loadReportsSuccess = createAction(
  '[Reports] Load Reports Success',
  props<{ data: any }>()
);

export const loadReportsFailure = createAction(
  '[Reports] Load Reports Failure',
  props<{ error: any }>()
);

export const sendReport = createAction(
  '[Reports] Send Report',
  props<{
    reportText?: string;
    file?: File;
    selectedCategory: string;
    selectedSubCategory: string;
    options: AdvancedOptionsState
  }>()
);

export const sendReportSuccess = createAction(
  '[Reports] Send Report Success',
  props<{ taskId: string }>()
);

export const sendReportFail = createAction(
  '[Reports] Send Report Fail',
  props<{ error: any }>()
);

export const updateProcessReportStatus = createAction(
  '[Reports] Update Report Status',
  props<{
    taskId: string,
    state: ProcessingState
  }>()
);

export const reportProcessingSuccess = createAction(
  '[Reports] Report Processing Success',
  props<{
    taskId: string
  }>()
);

export const fetchReportResultSuccess = createAction(
  '[Reports] Fetch Report Success',
  props<{
    data: NLPOutput
  }>()
);

export const cancelReport = createAction(
  '[Reports] Cancel Fetch Report',
);

import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, Renderer2 } from '@angular/core';
import { distinctUntilChanged, filter, map, takeWhile, tap } from 'rxjs/operators';
import { LabelService } from '../../services/label.service';
import type { Label } from './label.model';

export interface Rect {
  width: number,
  height: number,
  x: number,
  y: number,
  index: number;
}

@Directive({
  selector: '[appLabel]'
})
export class LabelDirective implements AfterViewInit, OnDestroy {
  private static padding = 4;
  private static vOffset = 18;

  @Input()
  labelFrame: SVGRectElement;

  @Input()
  labelText: SVGTextElement;

  @Input()
  index: number;

  @Input('appLabel')
  label: Label;

  private rect: Rect;
  private absRect: Rect;
  private alive: boolean;

  constructor(public el: ElementRef, private renderer: Renderer2, private labelService: LabelService) { }

  ngAfterViewInit(): void {
    this.alive = true;
    this.rect = this.getTextRect();
    this.absRect = this.getLabelRect();
    this.labelService.add(this.label.id, this.absRect);
    this.renderer.setAttribute(this.el.nativeElement, 'transform', 'translate(0, 0)');
    this.renderer.setAttribute(this.labelFrame, 'transform', this.getLabelRectTransform(this.rect));
    this.renderer.setAttribute(this.labelFrame, 'width', `${this.rect.width}`);
    this.renderer.setAttribute(this.labelFrame, 'height', `${this.rect.height}`);
    this.labelService.visibleLabels$.pipe(
      distinctUntilChanged(),
      tap(labels => {
        const {
          horizontal,
          vertical
        } = this.labelService.search(this.absRect, this.label.id);
        const isOverlapping = horizontal.length > 0 && vertical.length > 0;
        if (isOverlapping) {
          let stepOverCount = 0;
          const rects = vertical
            .filter(v => horizontal.includes(v))
            .map(id => ({
              id,
              ...this.labelService.getRect(id)
            }))
            .sort((a, b) => a.x - b.x);

          const y = rects.reduce<number>((min, other) => {
            const thisIsAbove = this.index > other.index;
            if (thisIsAbove) {
              stepOverCount++;
              const otherY = this.labelService.getOffset(other.id) || 0;
              return min - other.height - otherY - LabelDirective.padding * stepOverCount;
            }
            return min;
          }, 0);
          this.labelService.setOffset(this.label.id, y);
          const transform = `translate(0, ${y})`;
          this.renderer.setAttribute(this.el.nativeElement, 'transform', transform);
        }
      }),
      takeWhile(() => this.alive)
    ).subscribe();
  }
  ngOnDestroy(): void {
    this.alive = false;
    this.labelService.remove(this.label.id);
  }

  getLabelRectTransform(rect: Rect) {
    const x = rect.x - LabelDirective.padding;
    const y = rect.y - rect.height / 2 - LabelDirective.padding / 2;
    return `translate(${x}, ${y})`;
  }

  private getTextRect(): Rect {
    const {
      width,
      height,
      x,
      y
    } = this.labelText.getBBox();
    return {
      width: width + LabelDirective.padding * 2,
      height: height + LabelDirective.padding,
      x,
      y,
      index: 1
    }
  }

  private getLabelRect(): Rect {
    const {
      width,
      height,
      x,
      y
    } = (this.labelText.parentNode as SVGGElement).getBoundingClientRect();
    return {
      width: width + LabelDirective.padding * 2,
      height: height + LabelDirective.padding,
      x,
      y,
      index: this.index
    }
  }

}

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Span } from '../store/span/span.model';
import { selectAllSpans, State } from '../store/span/span.reducer';

@Component({
  selector: 'app-meta',
  templateUrl: './meta.component.html',
  styleUrls: ['./meta.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MetaComponent implements OnInit {

  spans$: Observable<Span[]>;


  constructor(private store: Store<State>) { }

  ngOnInit(): void {
    this.spans$ = this.store.select(selectAllSpans);
  }

  identify(index: number, span: Span) {
    return span.id;
  }
}

import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Dictionary } from '@ngrx/entity';
import { Entity } from './entity.model';
import * as EntityActions from './entity.actions';
import { selectSectionMap } from '../section/section.reducer';
import { Section } from '../section/section.model';
import { getSelectedOntology } from '../ontologies/ontologies.reducer';
import { collectAllTypes } from './entity.helpers';
export const entitiesFeatureKey = 'entities';

export interface State extends EntityState<Entity> {
  disabledTypes: string[];
  highlightedEntity: string;
  highlightedSpans: string[];
}

export const adapter: EntityAdapter<Entity> = createEntityAdapter<Entity>();

export const initialState: State = adapter.getInitialState({
  disabledTypes: [],
  highlightedEntity: null,
  highlightedSpans: []
});


export const reducer = createReducer(
  initialState,
  on(EntityActions.addEntity,
    (state, action) => adapter.addOne(action.entity, state)
  ),
  on(EntityActions.upsertEntity,
    (state, action) => adapter.upsertOne(action.entity, state)
  ),
  on(EntityActions.addEntities,
    (state, action) => adapter.addMany(action.entities, state)
  ),
  on(EntityActions.upsertEntities,
    (state, action) => adapter.upsertMany(action.entities, state)
  ),
  on(EntityActions.updateEntity,
    (state, action) => adapter.updateOne(action.entity, state)
  ),
  on(EntityActions.updateEntities,
    (state, action) => adapter.updateMany(action.entities, state)
  ),
  on(EntityActions.deleteEntity,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(EntityActions.deleteEntities,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(EntityActions.loadEntities,
    (state, action) => adapter.setAll(action.entities, state)
  ),
  on(EntityActions.clearEntities,
    state => adapter.removeAll(state)
  ),
  on(EntityActions.toggleEntityType,
    (state, { entityType }) => {
      const disabledTypes = new Set(state.disabledTypes);
      if (disabledTypes.has(entityType)) {
        disabledTypes.delete(entityType);
      } else {
        disabledTypes.add(entityType);
      }
      return {
        ...state,
        disabledTypes: [...disabledTypes.values()]
      }
    }
  ),
  on(EntityActions.toggleAll, (state: State, { disableAllTypes, selectedOntology }) => {
    if (disableAllTypes) {
      const ids = [...state.ids.values()] as string[];
      const allEntities = ids.map<Entity>(id => state.entities[id]);
      const allTypes = collectAllTypes(allEntities, selectedOntology)
      return {
        ...state,
        disabledTypes: [...allTypes.keys()]
      }
    }
    return {
      ...state,
      disabledTypes: []
    }
  }),
  on(EntityActions.setHighlighted, (state: State, { id }) => {
    return {
      ...state,
      highlightedEntity: id
    }
  }),
  on(EntityActions.setHighlightedSpans, (state: State, { ids }) => {
    return {
      ...state,
      highlightedSpans: ids
    }
  })
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

const getEntitiesState = createFeatureSelector<State>(entitiesFeatureKey);
export const selectAllEntities = createSelector(getEntitiesState, selectAll);
export const selectEntitiesMap = createSelector(getEntitiesState, selectEntities);
export const selectEntityIds = createSelector(getEntitiesState, selectIds);
export const selectDisabledTypes = createSelector(getEntitiesState, state => state.disabledTypes);
export const selectLocalEntities = createSelector(
  selectAllEntities,
  selectSectionMap,
  (entities: Entity[], sections: Dictionary<Section>, sectionId: string) => {
    const section = sections[sectionId];
    if (!section) {
      console.warn(`Could not find section id [${sectionId}]`);
      return [];
    }

    return entities
      .filter(entity => {
        return entity.locations.includes(sectionId);
      })
      .map(entity => {
        const selfStart = entity.spans[0].start;
        const selfEnd = entity.spans[0].end;
        const sectionStart = section.spans[0].start;
        const start = selfStart - sectionStart;
        const end = selfEnd - sectionStart;
        return {
          ...entity,
          spans: [
            {
              start,
              end
            }
          ]
        } as Entity;
      })
  }
);

export const selectEntityById = createSelector(
  selectAllEntities,
  (entities: Entity[], id: string) => {
    return entities.find(entity => {
      return entity.id === id;
    });
  }
);

export const selectEntityTypes = createSelector(
  selectAllEntities,
  getSelectedOntology,
  collectAllTypes
);

export const selectAllTypesDisabled = createSelector(
  selectEntityTypes,
  selectDisabledTypes,
  (allTypes, disabledTypes) => {
    const types = [...allTypes.keys()];
    if (types.length === 0 || disabledTypes.length === 0) {
      return false;
    }
    return types.every(entityType => disabledTypes.includes(entityType));
  }
);

export const selectHighlightedEntity = createSelector(getEntitiesState, state => state.highlightedEntity);
export const selectHighlightedSpans = createSelector(getEntitiesState, state => state.highlightedSpans);
export const selectOntologyEntity = createSelector(
  getSelectedOntology,
  selectAllEntities,
  (selectedOntology, entities) =>
    entities.filter(entity => Object.keys(entity.entity_type).includes(selectedOntology))
);

import { Action, createReducer, on } from '@ngrx/store';
import { environment } from 'projects/visual-client/src/environments/environment';
import * as AppStateActions from './app-state.actions';

export const appStateFeatureKey = 'appState';

export interface State {
  iamServer: string;
  emtelliproServer: string;
  serverOverride?: string;
  allowedExtensions: string[];
  enabledFeatures: string[];
}

export const initialState: State = {
  emtelliproServer: environment.emtelliproServer,
  iamServer: environment.iamServer,
  allowedExtensions: [],
  enabledFeatures: []
};


export const reducer = createReducer(
  initialState,

  on(AppStateActions.loadAppStates, state => state),
  on(AppStateActions.fetchAppConfigSuccess, (state, { emtelliproServer, iamServer, allowedExtensions, enabledFeatures }) => {
    return {
      ...state,
      emtelliproServer: emtelliproServer || state.emtelliproServer,
      serverOverride: null,
      iamServer: iamServer || state.iamServer,
      allowedExtensions,
      enabledFeatures
    }
  }),
  on(AppStateActions.setServerOverride, (state, { url }) => {
    return {
      ...state,
      serverOverride: url
    }
  })
);

